import React, { useState, useEffect } from 'react';
import About from '../components/AboutMe/AboutMe';
import Layout from '../components/Layout/Layout';
import Skills from '../components/Skills/Skills';
import Contact from '../components/Contact/Contact';
import { SEO } from "../components/SEO/SEO";

import { PortfolioProvider } from '../context/context';

import { headerData, aboutData, skillsData, experienceData, contactData, footerData } from '../mock/data';

function about() {
  const [header, setHeader] = useState({});
  const [about, setAbout] = useState({});
  const [skills, setSkills] = useState({});
  const [experiences, setExperience] = useState({});
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setAbout({ ...aboutData });
    setSkills({ ...skillsData });
    setExperience({ ...experienceData });
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, about, skills, experiences, contact, footer }}>
      <Layout>
      <SEO title={'Nick Jeske - About | Software Engineer & Blogger'}/>
      <About />
      <Skills />
      <Contact />
      </Layout>
    </PortfolioProvider>
  );
}

export default about;

// export const Head = () => (
//   <SEO title={'Nick Jeske - About | Software Engineer & Blogger'}/>
// );
